function ErrorIcon() {
  return <svg width="378" height="276" viewBox="0 0 378 276" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ErrorIcon" data-sentry-source-file="ErrorIcon.jsx">
            <g clipPath="url(#clip0_7350_42545)" data-sentry-element="g" data-sentry-source-file="ErrorIcon.jsx">
                <path d="M236.79 129.312C266.344 99.7568 266.344 51.8389 236.79 22.284C207.235 -7.27088 159.317 -7.27087 129.762 22.284C100.207 51.8389 100.207 99.7568 129.762 129.312C159.317 158.867 207.235 158.867 236.79 129.312Z" fill="#EE9730" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M377.16 201.775L359.77 201.735L334.06 201.675L281.68 201.555C281.68 201.555 281.69 201.555 281.71 201.545L334.32 172.355L377.17 201.775H377.16Z" fill="#EDB188" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M377.161 201.775L359.771 201.735C354.631 191.925 339.601 186.385 335.411 183.645C330.181 180.235 334.321 172.345 334.321 172.345L377.171 201.765L377.161 201.775Z" fill="#EFBE97" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M334.05 201.685L281.67 201.565C281.67 201.565 281.68 201.565 281.7 201.555C282.38 201.245 295.37 195.475 305.36 197.605C314.2 199.495 329.41 198.225 334.05 201.685Z" fill="#E8A577" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M195.45 201.775L159.85 201.695L107.22 201.575L0 201.335C0 201.335 0.02 201.335 0.06 201.305L107.75 141.545L195.46 201.765L195.45 201.775Z" fill="#EDB188" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M195.449 201.776L159.849 201.696C149.329 181.616 118.549 170.266 109.979 164.676C99.2793 157.686 107.739 141.556 107.739 141.556L195.449 201.776Z" fill="#EFBE97" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M107.22 201.585L0 201.345C0 201.345 0.02 201.345 0.06 201.315C1.46 200.685 28.05 188.865 48.5 193.235C66.6 197.105 97.72 194.505 107.23 201.585H107.22Z" fill="#E8A577" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M329.97 201.775L305.2 201.725L268.58 201.645L193.98 201.475C193.98 201.475 194 201.475 194.02 201.455L268.95 159.875L329.97 201.775Z" fill="#EFBE97" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M329.97 201.775L305.2 201.725C297.88 187.755 276.47 179.855 270.5 175.965C263.06 171.105 268.94 159.875 268.94 159.875L329.96 201.775H329.97Z" fill="#F5CDA9" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M268.58 201.646L193.98 201.476C193.98 201.476 194 201.476 194.02 201.456C194.99 201.016 213.5 192.796 227.72 195.836C240.31 198.526 261.97 196.716 268.58 201.646Z" fill="#EDB188" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M321.19 140.305C316.04 140.435 312 144.805 312 149.955V201.925H330.89V149.745C330.89 144.445 326.53 140.165 321.2 140.305H321.19Z" fill="#D3573B" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M321.44 140.295C318.48 140.295 316.08 144.525 316.08 149.735V201.915H326.8V149.735C326.8 144.515 324.4 140.295 321.44 140.295Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M321.44 140.295C320.33 140.295 319.43 144.525 319.43 149.735V201.915H323.45V149.735C323.45 144.515 322.55 140.295 321.44 140.295Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M317.581 147.345L316.351 148.425L316.101 146.105L315.851 148.425L314.611 147.345L315.771 149.165L316.101 149.685V149.675V149.685L316.431 149.165L317.581 147.345Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M328.271 147.345L327.041 148.425L326.791 146.105H326.781L326.531 148.425L325.301 147.345L326.451 149.165H326.461L326.781 149.685V149.675L326.791 149.685L327.121 149.165L328.271 147.345Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.931 150.926L321.691 152.006L321.441 149.686L321.191 152.006L319.961 150.926L321.111 152.746L321.441 153.256L321.771 152.746V152.736L322.931 150.926Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.931 159.825L321.691 160.905L321.441 158.585L321.191 160.905L319.961 159.825L321.111 161.645L321.441 162.165V162.155V162.165L321.771 161.645L322.931 159.825Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.931 168.726L321.691 169.816L321.441 167.496L321.191 169.816L319.961 168.726L321.111 170.546V170.556L321.441 171.066L321.771 170.546L322.931 168.726Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M317.581 155.605L316.351 156.695L316.101 154.375L315.851 156.695L314.611 155.605L315.771 157.425L316.101 157.945L316.431 157.425L317.581 155.605Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M328.271 155.605L327.041 156.695L326.791 154.375H326.781L326.531 156.695L325.301 155.605L326.451 157.425H326.461L326.781 157.945H326.791L327.121 157.425L328.271 155.605Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M317.581 163.875L316.351 164.955L316.101 162.635L315.851 164.955L314.611 163.875L315.771 165.695L316.101 166.215V166.205V166.215L316.431 165.695V165.685L317.581 163.875Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M328.271 163.875L327.041 164.955L326.791 162.635H326.781L326.531 164.955L325.301 163.875L326.451 165.695H326.461L326.781 166.215V166.205L326.791 166.215L327.121 165.695V165.685L328.271 163.875Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M304.12 154.485V151.145C304.12 149.005 302.52 147.095 300.39 146.985C298.12 146.865 296.23 148.675 296.23 150.925V158.675C296.23 162.285 299.15 165.205 302.76 165.205H312.81V157.315H306.96C305.4 157.315 304.13 156.045 304.13 154.485H304.12Z" fill="#D3573B" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M302.76 162.465C300.67 162.465 298.98 160.775 298.98 158.685V150.935C298.98 150.275 299.52 149.735 300.18 149.735C300.86 149.765 301.38 150.415 301.38 151.155V157.165C301.38 158.765 302.68 160.065 304.28 160.065H312.01V162.455H302.77L302.76 162.465Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M304.29 161.305L303.06 162.385L302.81 160.065H302.8L302.55 162.385L301.32 161.305L302.47 163.125H302.48L302.8 163.635H302.81L303.14 163.125V163.115L304.29 161.305Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M300.46 155.605L299.23 156.695L298.98 154.375H298.97L298.72 156.695L297.49 155.605L298.65 157.425L298.97 157.945H298.98L299.31 157.425L300.46 155.605Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M302.8 150.545L301.57 151.625L301.32 149.305L301.07 151.625L299.83 150.545L300.99 152.365L301.32 152.875L301.65 152.365V152.355L302.8 150.545Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M310.031 161.305L308.801 162.385L308.551 160.065L308.301 162.385L307.061 161.305L308.221 163.125L308.551 163.635L308.881 163.125V163.115L310.031 161.305Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M339.571 170.866V167.526C339.571 165.386 341.171 163.476 343.301 163.366C345.571 163.246 347.461 165.056 347.461 167.306V175.056C347.461 178.666 344.541 181.586 340.931 181.586H330.881V173.696H336.731C338.291 173.696 339.561 172.426 339.561 170.866H339.571Z" fill="#D3573B" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M340.931 178.835C343.021 178.835 344.711 177.145 344.711 175.055V167.305C344.711 166.645 344.171 166.105 343.511 166.105C342.831 166.135 342.311 166.785 342.311 167.525V173.535C342.311 175.135 341.011 176.435 339.411 176.435H330.881V178.825H340.931V178.835Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M339.4 177.675L340.63 178.765L340.88 176.445L341.13 178.765L342.37 177.675L341.21 179.495L340.88 180.015L340.55 179.495L339.4 177.675Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M343.23 171.986L344.46 173.066L344.71 170.746L344.96 173.066L346.2 171.986L345.04 173.806L344.71 174.326V174.316V174.326L344.38 173.806L343.23 171.986Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M340.881 166.915L342.121 167.995L342.371 165.675L342.621 168.005L343.851 166.915L342.701 168.735L342.371 169.255L342.041 168.735L340.881 166.915Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M333.66 177.675L334.89 178.765L335.14 176.445L335.39 178.765L336.63 177.675L335.47 179.495L335.14 180.015L334.81 179.495L333.66 177.675Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M317.581 173.285L316.351 174.375L316.101 172.055L315.851 174.375L314.611 173.285L315.771 175.115L316.101 175.625L316.431 175.115V175.105L317.581 173.285Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M328.271 173.285L327.041 174.375L326.791 172.055H326.781L326.531 174.375L325.301 173.285L326.451 175.115H326.461L326.781 175.625H326.791L327.121 175.115V175.105L328.271 173.285Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.931 176.865L321.691 177.945L321.441 175.625L321.191 177.945L319.961 176.865L321.111 178.685L321.441 179.205V179.195V179.205L321.771 178.685L322.931 176.865Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.931 185.765L321.691 186.855L321.441 184.535L321.191 186.855L319.961 185.765L321.111 187.595L321.441 188.105L321.771 187.595V187.585L322.931 185.765Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.931 194.675L321.691 195.755L321.441 193.435L321.191 195.755L319.961 194.675L321.111 196.495L321.441 197.015V197.005V197.015L321.771 196.495L322.931 194.675Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M317.581 181.555L316.351 182.635L316.101 180.315L315.851 182.635L314.611 181.555L315.771 183.375L316.101 183.895V183.885V183.895L316.431 183.375L317.581 181.555Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M328.271 181.555L327.041 182.635L326.791 180.315H326.781L326.531 182.635L325.301 181.555L326.451 183.375H326.461L326.781 183.895V183.885L326.791 183.895L327.121 183.375L328.271 181.555Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M317.581 189.815L316.351 190.895L316.101 188.575L315.851 190.905L314.611 189.815L315.771 191.635L316.101 192.155L316.431 191.635L317.581 189.815Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M328.271 189.815L327.041 190.895L326.791 188.575H326.781L326.531 190.905L325.301 189.815L326.451 191.635H326.461L326.781 192.155H326.791L327.121 191.635L328.271 189.815Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.28 137.095C322.28 137.095 322.46 134.115 321.44 132.155C320.42 134.115 320.6 137.095 320.6 137.095H322.27H322.28Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M322.579 136.135C322.329 136.545 322.059 136.755 321.799 136.755H321.089C320.819 136.755 320.559 136.545 320.309 136.135L319.029 134.035L319.819 137.625C320.249 139.585 320.839 140.685 321.449 140.685C322.059 140.685 322.639 139.585 323.079 137.625L323.869 134.035L322.589 136.135H322.579Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M323.18 136.805C322.8 137.155 322.39 137.335 321.98 137.335H320.89C320.48 137.335 320.08 137.155 319.69 136.805L317.73 135.015L318.94 138.075C319.6 139.745 320.5 140.685 321.43 140.685C322.36 140.685 323.26 139.745 323.92 138.075L325.13 135.015L323.17 136.805H323.18Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M324 137.455C323.44 137.745 322.84 137.895 322.24 137.895H320.64C320.04 137.895 319.45 137.745 318.88 137.455L316.01 135.965L317.79 138.505C318.76 139.895 320.07 140.675 321.44 140.675C322.81 140.675 324.12 139.895 325.09 138.505L326.87 135.965L324 137.455Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M300.371 145.495C300.371 145.495 300.491 143.595 299.841 142.355C299.191 143.605 299.311 145.495 299.311 145.495H300.371Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M300.561 144.885C300.401 145.145 300.231 145.285 300.061 145.285H299.611C299.441 145.285 299.271 145.155 299.111 144.885L298.301 143.545L298.801 145.825C299.071 147.075 299.451 147.775 299.831 147.775C300.211 147.775 300.591 147.075 300.861 145.825L301.361 143.545L300.551 144.885H300.561Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M300.94 145.315C300.7 145.535 300.44 145.655 300.18 145.655H299.49C299.23 145.655 298.97 145.545 298.73 145.315L297.48 144.175L298.25 146.115C298.67 147.175 299.24 147.775 299.83 147.775C300.42 147.775 300.99 147.175 301.41 146.115L302.18 144.175L300.93 145.315H300.94Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M301.461 145.736C301.101 145.926 300.721 146.016 300.341 146.016H299.321C298.941 146.016 298.561 145.926 298.201 145.736L296.371 144.786L297.501 146.406C298.121 147.286 298.951 147.786 299.821 147.786C300.691 147.786 301.531 147.286 302.141 146.406L303.271 144.786L301.441 145.736H301.461Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M344.17 161.055C344.17 161.055 344.32 158.605 343.48 156.985C342.64 158.595 342.79 161.055 342.79 161.055H344.17Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M344.42 160.265C344.21 160.605 344 160.775 343.78 160.775H343.19C342.97 160.775 342.75 160.605 342.55 160.265L341.5 158.535L342.15 161.495C342.51 163.105 342.99 164.015 343.49 164.015C343.99 164.015 344.47 163.105 344.83 161.495L345.48 158.535L344.43 160.265H344.42Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M344.92 160.825C344.6 161.115 344.27 161.265 343.93 161.265H343.03C342.69 161.265 342.36 161.115 342.04 160.825L340.43 159.355L341.43 161.875C341.97 163.245 342.71 164.025 343.48 164.025C344.25 164.025 344.99 163.255 345.53 161.875L346.53 159.355L344.92 160.825Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M345.59 161.355C345.13 161.595 344.64 161.715 344.14 161.715H342.83C342.34 161.715 341.85 161.595 341.38 161.355L339.01 160.125L340.47 162.225C341.27 163.365 342.35 164.015 343.48 164.015C344.61 164.015 345.69 163.375 346.49 162.225L347.95 160.125L345.58 161.355H345.59Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M216.19 106.985L186.89 98.625L183.27 118.055L193.67 123.915L196.86 139.605L196.95 164.595L200.61 184.555L187.14 182.255L186.68 187.085L184.41 205.675L187.14 209.115L214.63 195.945L224.68 163.095L226.72 141.245L216.18 106.975L216.19 106.985Z" fill="#421819" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M170.14 139.605L173.3 164.595L176 183.105L185.21 187.475L188.61 206.625L163.28 192.525L150.55 163.105L150.43 141.255L159.65 108.595L184.35 100.285L186.63 117.185L180.68 124.045L170.14 139.605Z" fill="#612423" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M126.071 165.295L141.301 163.775L144.941 184.185L129.521 181.525L132.551 205.865L108.591 202.245L106.721 182.485L66.9609 181.175L67.3909 168.245L81.7509 146.805L92.7909 122.665L106.241 100.025L106.861 100.665L131.621 99.7148L134.821 132.225L126.071 165.305V165.295ZM81.5609 164.075L107.041 163.785L102.901 131.595L105.171 130.365L106.421 135.745L81.5609 164.085V164.075Z" fill="#421819" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M142.59 162.055L139.72 178.665L104.04 181.955L65.5403 185.015L64.5703 167.305L77.7203 144.235L89.7403 120.635L107.53 99.165L105.33 131.045L85.3703 163.995L114.22 167.565L142.59 162.055Z" fill="#612423" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M293.721 165.295L308.951 163.775L312.591 184.185L297.171 181.525L300.201 205.865L276.241 202.245L274.371 182.485L234.611 181.175L235.041 168.245L249.391 146.805L260.431 122.665L273.881 100.025L274.501 100.665L299.261 99.7148L302.461 132.225L293.711 165.305L293.721 165.295ZM249.201 164.075L274.681 163.785L270.541 131.595L272.811 130.365L274.061 135.745L249.201 164.085V164.075Z" fill="#421819" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M310.231 162.055L307.361 178.665L271.681 181.955L233.181 185.015L232.221 167.305L245.371 144.235L257.391 120.635L275.171 99.165L272.981 131.045L253.021 163.995L281.861 167.565L310.231 162.055Z" fill="#612423" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M53.2911 101.295C46.8911 101.455 41.8711 106.885 41.8711 113.285V213.465H65.3211V113.025C65.3211 106.445 59.9011 101.135 53.2911 101.305V101.295Z" fill="#D3573B" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M53.6014 101.295C49.9214 101.295 46.9414 106.545 46.9414 113.015V213.455H60.2514V113.015C60.2514 106.535 57.2714 101.295 53.5914 101.295H53.6014Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M53.6016 101.295C52.2216 101.295 51.1016 106.545 51.1016 113.015V213.455H56.0916V113.015C56.0916 106.535 54.9716 101.295 53.5916 101.295H53.6016Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 110.045L47.2811 111.385L46.9711 108.505H46.9611L46.6511 111.385L45.1211 110.045L46.5511 112.305L46.9611 112.945V112.935V112.945L47.3811 112.305V112.295L48.8111 110.045Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 110.045L60.5506 111.385L60.2306 108.505L59.9206 111.385L58.3906 110.045L59.8206 112.305L60.2306 112.945V112.935V112.945L60.6406 112.305V112.295L62.0806 110.045Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 114.485L53.912 115.825L53.602 112.945L53.292 115.825L51.752 114.485L53.192 116.745L53.602 117.385V117.375V117.385L54.012 116.745V116.735L55.442 114.485Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 125.535L53.912 126.875L53.602 123.995L53.292 126.885L51.752 125.535L53.192 127.795L53.602 128.435L54.012 127.795L55.442 125.535Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 136.595L53.912 137.935L53.602 135.055L53.292 137.935L51.752 136.595L53.192 138.855L53.602 139.495V139.485V139.495L54.012 138.855V138.845L55.442 136.595Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 120.305L47.2811 121.645L46.9711 118.765H46.9611L46.6511 121.645L45.1211 120.305L46.5511 122.565L46.9611 123.205V123.195V123.205L47.3811 122.565V122.555L48.8111 120.305Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 120.305L60.5506 121.645L60.2306 118.765L59.9206 121.645L58.3906 120.305L59.8206 122.565L60.2306 123.205V123.195V123.205L60.6406 122.565V122.555L62.0806 120.305Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 130.565L47.2811 131.905L46.9711 129.025H46.9611L46.6511 131.905L45.1211 130.565L46.5511 132.825L46.9611 133.465V133.455V133.465L47.3811 132.825V132.815L48.8111 130.565Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 130.565L60.5506 131.905L60.2306 129.025L59.9206 131.905L58.3906 130.565L59.8206 132.825L60.2306 133.465V133.455V133.465L60.6406 132.825V132.815L62.0806 130.565Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M32.0908 118.915V114.775C32.0908 112.125 30.1008 109.745 27.4608 109.605C24.6408 109.455 22.3008 111.705 22.3008 114.495V124.115C22.3008 128.595 25.9308 132.225 30.4108 132.225H42.8808V122.435H35.6108C33.6708 122.435 32.0908 120.865 32.0908 118.915Z" fill="#D3573B" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M30.4009 128.815C27.8109 128.815 25.7109 126.715 25.7109 124.125V114.505C25.7109 113.685 26.3809 113.025 27.2009 113.025C28.0409 113.065 28.6809 113.875 28.6809 114.785V122.255C28.6809 124.245 30.2909 125.855 32.2809 125.855H41.8709V128.825H30.4009V128.815Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M32.3011 127.365L30.7711 128.715L30.4611 125.835L30.1511 128.715L28.6211 127.365L30.0511 129.625V129.635L30.4611 130.275V130.265V130.275L30.8711 129.625L32.3011 127.365Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M27.5513 120.305L26.0213 121.645L25.7113 118.765L25.4013 121.645L23.8613 120.305L25.3013 122.565L25.7113 123.205V123.195V123.205L26.1213 122.565V122.555L27.5513 120.305Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M30.4615 114.015L28.9315 115.355L28.6215 112.475L28.3115 115.355L26.7715 114.015L28.2115 116.275L28.6215 116.915V116.905V116.915L29.0315 116.275V116.265L30.4615 114.015Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M39.43 127.365L37.9 128.715L37.59 125.835L37.28 128.715L35.75 127.365L37.18 129.625V129.635L37.59 130.275V130.265V130.275L38 129.625L39.43 127.365Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M76.1103 173.665V169.525C76.1103 166.875 78.1003 164.495 80.7403 164.355C83.5603 164.205 85.9003 166.455 85.9003 169.245V178.865C85.9003 183.345 82.2703 186.975 77.7903 186.975H65.3203V177.185H72.5903C74.5303 177.185 76.1103 175.615 76.1103 173.665Z" fill="#D3573B" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M77.8003 183.565C80.3903 183.565 82.4903 181.465 82.4903 178.875V169.255C82.4903 168.435 81.8203 167.775 81.0003 167.775C80.1603 167.815 79.5203 168.625 79.5203 169.535V177.005C79.5203 178.995 77.9103 180.605 75.9203 180.605H65.3203V183.575H77.7903L77.8003 183.565Z" fill="#F46640" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M75.8906 182.125L77.4206 183.465L77.7306 180.585H77.7406L78.0506 183.465L79.5806 182.125L78.1406 184.385L77.7406 185.025H77.7306L77.3206 184.385V184.375L75.8906 182.125Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M80.6504 175.055L82.1804 176.395L82.4904 173.515L82.8004 176.405L84.3304 175.055L82.9004 177.315L82.4904 177.955L82.0804 177.315L80.6504 175.055Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M77.7402 168.765L79.2702 170.105L79.5802 167.225L79.8902 170.105L81.4202 168.765L79.9902 171.025L79.5802 171.665L79.1702 171.025V171.015L77.7402 168.765Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M68.7598 182.125L70.2898 183.465L70.5998 180.585H70.6098L70.9198 183.465L72.4498 182.125L71.0198 184.385L70.6098 185.025L70.1898 184.385L70.1998 184.375L68.7598 182.125Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 142.255L47.2811 143.595L46.9711 140.715H46.9611L46.6511 143.595L45.1211 142.255L46.5511 144.515L46.9611 145.155L47.3811 144.515V144.505L48.8111 142.255Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 142.255L60.5506 143.595L60.2306 140.715L59.9206 143.595L58.3906 142.255L59.8206 144.515L60.2306 145.155L60.6406 144.515V144.505L62.0806 142.255Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 146.695L53.912 148.035L53.602 145.155L53.292 148.035L51.752 146.695L53.192 148.955L53.602 149.595V149.585V149.595L54.012 148.955V148.945L55.442 146.695Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 157.745L53.912 159.095L53.602 156.205L53.292 159.095L51.752 157.745L53.192 160.005L53.602 160.645L54.012 160.005L55.442 157.745Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 168.805L53.912 170.145L53.602 167.265L53.292 170.145L51.752 168.805L53.192 171.065L53.602 171.705V171.695V171.705L54.012 171.065V171.055L55.442 168.805Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 152.515L47.2811 153.855L46.9711 150.975H46.9611L46.6511 153.855L45.1211 152.515L46.5511 154.775L46.9611 155.415V155.405V155.415L47.3811 154.775V154.765L48.8111 152.515Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 152.515L60.5506 153.855L60.2306 150.975L59.9206 153.855L58.3906 152.515L59.8206 154.775L60.2306 155.415V155.405V155.415L60.6406 154.775V154.765L62.0806 152.515Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 162.775L47.2811 164.115L46.9711 161.235H46.9611L46.6511 164.115L45.1211 162.775L46.5511 165.035L46.9611 165.675V165.665V165.675L47.3811 165.035V165.025L48.8111 162.775Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 162.775L60.5506 164.115L60.2306 161.235L59.9206 164.115L58.3906 162.775L59.8206 165.035L60.2306 165.675V165.665V165.675L60.6406 165.035V165.025L62.0806 162.775Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 175.095L47.2811 176.435L46.9711 173.555H46.9611L46.6511 176.445L45.1211 175.095L46.5511 177.355L46.9611 177.995L47.3811 177.355L48.8111 175.095Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 175.095L60.5506 176.435L60.2306 173.555L59.9206 176.445L58.3906 175.095L59.8206 177.355L60.2306 177.995L60.6406 177.355L62.0806 175.095Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 179.535L53.912 180.875L53.602 177.995L53.292 180.885L51.752 179.535L53.192 181.795L53.602 182.435L54.012 181.795L55.442 179.535Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 190.585L53.912 191.935L53.602 189.055L53.292 191.935L51.752 190.585L53.192 192.845V192.855L53.602 193.495V193.485V193.495L54.012 192.845L55.442 190.585Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.442 201.645L53.912 202.985L53.602 200.105L53.292 202.995L51.752 201.645L53.192 203.905L53.602 204.545L54.012 203.905L55.442 201.645Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 185.355L47.2811 186.695L46.9711 183.815H46.9611L46.6511 186.705L45.1211 185.355L46.5511 187.615L46.9611 188.255L47.3811 187.615L48.8111 185.355Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 185.355L60.5506 186.695L60.2306 183.815L59.9206 186.705L58.3906 185.355L59.8206 187.615L60.2306 188.255L60.6406 187.615L62.0806 185.355Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M48.8111 195.615L47.2811 196.955L46.9711 194.075H46.9611L46.6511 196.965L45.1211 195.615L46.5511 197.875L46.9611 198.515L47.3811 197.875L48.8111 195.615Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M62.0806 195.615L60.5506 196.955L60.2306 194.075L59.9206 196.965L58.3906 195.615L59.8206 197.875L60.2306 198.515L60.6406 197.875L62.0806 195.615Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M54.6412 97.3151C54.6412 97.3151 54.8712 93.6151 53.6012 91.1851C52.3312 93.6151 52.5612 97.3151 52.5612 97.3151H54.6412Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.0116 96.1251C54.7016 96.6351 54.3716 96.8951 54.0416 96.8951H53.1616C52.8316 96.8951 52.5016 96.6351 52.1916 96.1251L50.6016 93.5151L51.5816 97.9751C52.1216 100.405 52.8416 101.775 53.6016 101.775C54.3616 101.775 55.0816 100.405 55.6216 97.9751L56.6016 93.5151L55.0116 96.1251Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M55.762 96.9651C55.282 97.4051 54.782 97.6251 54.272 97.6251H52.922C52.412 97.6251 51.912 97.4051 51.432 96.9651L49.002 94.7451L50.502 98.5451C51.322 100.615 52.432 101.775 53.592 101.775C54.752 101.775 55.862 100.615 56.682 98.5451L58.182 94.7451L55.752 96.9651H55.762Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M56.7713 97.765C56.0713 98.125 55.3313 98.315 54.5913 98.315H52.6113C51.8713 98.315 51.1313 98.125 50.4313 97.765L46.8613 95.915L49.0613 99.075C50.2613 100.795 51.8913 101.765 53.5913 101.765C55.2913 101.765 56.9213 100.795 58.1213 99.075L60.3213 95.915L56.7513 97.765H56.7713Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M27.4306 107.745C27.4306 107.745 27.5806 105.395 26.7706 103.845C25.9606 105.395 26.1106 107.745 26.1106 107.745H27.4306Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M27.6713 106.995C27.4713 107.325 27.2613 107.485 27.0513 107.485H26.4913C26.2813 107.485 26.0713 107.315 25.8713 106.995L24.8613 105.335L25.4813 108.175C25.8213 109.725 26.2813 110.595 26.7613 110.595C27.2413 110.595 27.7013 109.725 28.0413 108.175L28.6613 105.335L27.6513 106.995H27.6713Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M28.1498 107.525C27.8498 107.805 27.5298 107.945 27.1998 107.945H26.3398C26.0198 107.945 25.6998 107.805 25.3898 107.525L23.8398 106.115L24.7998 108.525C25.3198 109.845 26.0298 110.585 26.7698 110.585C27.5098 110.585 28.2198 109.845 28.7398 108.525L29.6998 106.115L28.1498 107.525Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M28.7905 108.035C28.3505 108.265 27.8805 108.385 27.4005 108.385H26.1405C25.6705 108.385 25.2005 108.265 24.7505 108.035L22.4805 106.855L23.8805 108.865C24.6405 109.965 25.6805 110.575 26.7605 110.575C27.8405 110.575 28.8805 109.955 29.6405 108.865L31.0405 106.855L28.7705 108.035H28.7905Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M81.8198 161.485C81.8198 161.485 82.0098 158.435 80.9598 156.435C79.9198 158.435 80.0998 161.485 80.0998 161.485H81.8098H81.8198Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M82.1205 160.505C81.8605 160.925 81.5905 161.145 81.3205 161.145H80.5905C80.3205 161.145 80.0505 160.925 79.7905 160.505L78.4805 158.355L79.2905 162.025C79.7305 164.025 80.3305 165.155 80.9505 165.155C81.5705 165.155 82.1705 164.025 82.6105 162.025L83.4205 158.355L82.1105 160.505H82.1205Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M82.7399 161.195C82.3499 161.555 81.9299 161.735 81.5099 161.735H80.3999C79.9799 161.735 79.5699 161.555 79.1699 161.195L77.1699 159.365L78.4099 162.495C79.0899 164.205 79.9999 165.155 80.9599 165.155C81.9199 165.155 82.8299 164.195 83.5099 162.495L84.7499 159.365L82.7499 161.195H82.7399Z" fill="#3F1715" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M83.5704 161.855C82.9904 162.155 82.3904 162.305 81.7704 162.305H80.1404C79.5304 162.305 78.9204 162.155 78.3404 161.855L75.4004 160.335L77.2204 162.935C78.2104 164.355 79.5504 165.155 80.9504 165.155C82.3504 165.155 83.6904 164.355 84.6804 162.935L86.5004 160.335L83.5604 161.855H83.5704Z" fill="#21120D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M364.3 200.105C363.62 200.105 352.14 217.675 350.97 219.345C345.09 227.765 333.37 229.085 324.37 234.035C317.51 237.805 312.14 243.845 307.88 250.415C302.26 259.085 297.67 269.505 288.26 273.775C280.71 277.205 271.78 275.675 264.14 272.455C251.41 267.085 242.12 260.785 228.1 258.975C214.68 257.245 200.77 259.085 188.34 264.465C175.91 269.845 163.08 278.785 150.1 274.925C133.67 270.025 126.98 246.885 110.04 244.265C97.8396 242.375 86.8196 252.395 74.5096 253.295C62.5296 254.175 51.5796 246.225 43.9396 236.965C36.2996 227.695 30.7296 216.775 22.4496 208.065C19.4496 204.915 16.2496 202.335 12.8496 200.095H364.29L364.3 200.105Z" fill="#EE9730" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M364.3 200.105C363.62 200.105 352.13 217.665 350.96 219.355C345.09 227.775 333.36 229.095 324.36 234.045C317.51 237.815 312.13 243.855 307.88 250.425C302.26 259.095 297.67 269.515 288.26 273.785C280.71 277.205 271.78 275.685 264.14 272.465C251.4 267.105 242.12 260.795 228.1 258.995C214.69 257.265 200.77 259.105 188.34 264.485C175.91 269.855 163.08 278.815 150.11 274.945C133.68 270.045 126.98 246.905 110.05 244.285C97.8496 242.395 86.8396 252.415 74.5196 253.315C62.5396 254.195 51.5896 246.255 43.9396 236.985C36.2996 227.725 30.7296 216.805 22.4496 208.095C19.4496 204.945 16.2496 202.365 12.8496 200.125H38.0696C39.9396 202.815 41.8596 205.455 43.9396 207.975C51.5896 217.235 62.5296 225.185 74.5196 224.305C86.8396 223.405 97.8496 213.385 110.05 215.265C126.99 217.885 133.68 241.025 150.11 245.925C163.08 249.795 175.91 240.845 188.34 235.465C200.77 230.095 214.68 228.255 228.1 229.975C242.12 231.785 251.4 238.085 264.14 243.455C271.78 246.675 280.71 248.195 288.26 244.765C297.67 240.495 302.26 230.075 307.88 221.405C312.13 214.835 317.51 208.805 324.36 205.025C327.98 203.035 332.03 201.635 335.98 200.115H364.3V200.105Z" fill="#F9A42D" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M193.84 41.3852H265.8C265.53 34.4452 259.82 28.9052 252.81 28.9052C249.93 22.4052 243.43 17.8652 235.87 17.8652C226.78 17.8652 219.22 24.4152 217.65 33.0552C217.32 33.0152 216.99 32.9952 216.65 32.9952C213.72 32.9952 211.12 34.4152 209.49 36.5952C208.93 36.4552 208.35 36.3852 207.75 36.3852C205.63 36.3852 203.73 37.2952 202.41 38.7552C201.53 38.2952 200.52 38.0352 199.46 38.0352C197.03 38.0352 194.91 39.3952 193.83 41.3852H193.84Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M281.44 42.3952H184.88C184.52 42.3952 184.23 42.1052 184.23 41.7452C184.23 41.3852 184.52 41.0952 184.88 41.0952H281.44C281.8 41.0952 282.09 41.3852 282.09 41.7452C282.09 42.1052 281.8 42.3952 281.44 42.3952Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M210.23 46.0354H174.83C174.47 46.0354 174.18 45.7454 174.18 45.3854C174.18 45.0254 174.47 44.7354 174.83 44.7354H210.23C210.59 44.7354 210.88 45.0254 210.88 45.3854C210.88 45.7454 210.59 46.0354 210.23 46.0354Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M251.651 46.0354H232.211C231.851 46.0354 231.561 45.7454 231.561 45.3854C231.561 45.0254 231.851 44.7354 232.211 44.7354H251.651C252.011 44.7354 252.301 45.0254 252.301 45.3854C252.301 45.7454 252.011 46.0354 251.651 46.0354Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M159.081 78.2751H98.4512C98.6812 72.4251 103.491 67.7551 109.401 67.7551C111.821 62.2751 117.301 58.4551 123.671 58.4551C131.331 58.4551 137.691 63.9751 139.021 71.2551C139.301 71.2251 139.581 71.2051 139.861 71.2051C142.331 71.2051 144.521 72.4051 145.891 74.2451C146.361 74.1251 146.851 74.0651 147.351 74.0651C149.141 74.0651 150.741 74.8351 151.851 76.0551C152.591 75.6651 153.441 75.4451 154.341 75.4451C156.391 75.4451 158.171 76.5851 159.081 78.2651V78.2751Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M85.2705 79.1249H166.62C166.92 79.1249 167.16 78.8849 167.16 78.5849C167.16 78.2849 166.92 78.0449 166.62 78.0449H85.2705C84.9705 78.0449 84.7305 78.2849 84.7305 78.5849C84.7305 78.8849 84.9705 79.1249 85.2705 79.1249Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M145.27 82.185H175.1C175.4 82.185 175.64 81.945 175.64 81.645C175.64 81.345 175.4 81.105 175.1 81.105H145.27C144.97 81.105 144.73 81.345 144.73 81.645C144.73 81.945 144.97 82.185 145.27 82.185Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
                <path d="M110.37 82.185H126.75C127.05 82.185 127.29 81.945 127.29 81.645C127.29 81.345 127.05 81.105 126.75 81.105H110.37C110.07 81.105 109.83 81.345 109.83 81.645C109.83 81.945 110.07 82.185 110.37 82.185Z" fill="#FAFBED" data-sentry-element="path" data-sentry-source-file="ErrorIcon.jsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="ErrorIcon.jsx">
                <clipPath id="clip0_7350_42545" data-sentry-element="clipPath" data-sentry-source-file="ErrorIcon.jsx">
                    <rect width="377.16" height="275.77" fill="white" transform="translate(0 0.115234)" data-sentry-element="rect" data-sentry-source-file="ErrorIcon.jsx" />
                </clipPath>
            </defs>
        </svg>;
}
export default ErrorIcon;