import ErrorIcon from '@/components/icons/ErrorIcon';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
const Custom404 = () => {
  const router = useRouter();
  const t = useTranslations('Common');
  const handleExplore = () => {
    router.push('/');
  };
  return <div className="error-page" data-sentry-component="Custom404" data-sentry-source-file="404.jsx">
            <div className="container">
                <div className="txt-block">
                    <ErrorIcon data-sentry-element="ErrorIcon" data-sentry-source-file="404.jsx" />
                    <h3>{t('oops')}</h3>
                    <p>{t('somethingWentWrong')}</p>
                    <button onClick={handleExplore}>{t('explore')}</button>
                </div>
            </div>
        </div>;
};
export default Custom404;